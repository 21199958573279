
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Map/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Map/model.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller1 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Tracker/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Tracker/model.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller2 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/trackingPage/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/trackingPage/model.ts';
            var controllerExport2 = _controllerExport2;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://c5095236fd2e4b8fae9ed6d73cdd81a3@sentry.wixpress.com/4856',
      id: 'c5095236fd2e4b8fae9ed6d73cdd81a3',
      projectName: 'restaurants-order-tracker',
      teamName: 'orders-livesite',
      errorMonitor: true,
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"tracker.editor.page.title":"Order Tracking Page","tracker.page.order-id.empty-state":"None","tracker.page.pickup-details.title":"Pickup Details","tracker.page.delivery-details.title":"Delivery Details","tracker.page.delivery-details.address.title":"Delivery to:","tracker.page.pickup-details.address.title":"Pickup from:","tracker.page.delivery-details.due-by.title":"Delivery time:","tracker.page.pickup-details.due-by.title":"Pickup time:","tracker.page.order-details.order-note.title":"Order notes:","tracker.status.order-pending.title":"Pending","tracker.status.order-accepted.title":"Accepted","tracker.status.order-ready.title":"Ready","tracker.status.order-out-for-delivery.title":"Out For Delivery","tracker.status.order-fulfilled.title":"Fulfilled","tracker.status.order-canceled.title":"Canceled","tracker.status.order-unknown.title":"Unknown","tracker.status.order-pending.description":"Your order was received and it’s status will be updated here","tracker.status.order-accepted.description":"Your order was accepted and will be prepared accordingly.","tracker.status.order-ready.description":"Your order is ready","tracker.status.order-out-for-delivery.description":"Your order is on it’s way","tracker.status.order-fulfilled.description":"Enjoy your order!","tracker.status.order-canceled.description":"Your order was canceled","tracker.status.order-unknown.description":"Unknown"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "restaurants-order-tracker",
    biConfig: null,
    appName: "Restaurants Tracker app",
    appDefinitionId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Map/viewer.controller.ts",
          appName: "Restaurants Tracker app",
          appDefinitionId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767",
          projectName: "restaurants-order-tracker",
          componentName: "Map",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-dg4og" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Tracker/viewer.controller.ts",
          appName: "Restaurants Tracker app",
          appDefinitionId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767",
          projectName: "restaurants-order-tracker",
          componentName: "Tracker",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-x91qr" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/trackingPage/viewer.controller.ts",
          appName: "Restaurants Tracker app",
          appDefinitionId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767",
          projectName: "restaurants-order-tracker",
          componentName: "trackingPage",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-euxz6" }],
    false);

    export const createControllers = _createControllers
