import model from './model';
import { initOrderState } from './orderState';
import { getOrderInfo } from '../../api/orderClient';
import { getOrderDetails } from './orderDetails/orderDetails';
import { selectors, states } from './selectors';
import { OrderDetails } from './utils/types';
import { getOrderStatus } from '../../utils/utils';
import { oloLiveSiteTrackerPageLoaded } from '@wix/bi-logger-restaurants/v2';
import {
  TrackerFulfillmentType,
  TrackerPageLoadedParams,
} from '@wix/restaurants-bi';
import { UNKNOWN } from '../../constants/orderStatusState';

export default model.createController(
  ({ $bind, $w, $widget, initState, flowAPI }) => {
    const orderId =
      flowAPI.controllerConfig?.wixCodeApi?.location?.query?.orderId;
    const t = flowAPI.translations.t;

    const logger = flowAPI.bi;

    const { orderInfo, updateOrderInfo } = initOrderState(initState);

    return {
      pageReady: () => {
        // [TODO] - fetch business data
        $w(selectors.RestaurantDetailsBox).hide();

        if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
          $w(selectors.stateBox).changeState(states.ready);
          return;
        }
        if (!orderId) {
          $w(selectors.stateBox).changeState(states.error);
        } else {
          $w(selectors.stateBox).changeState(states.loader);
          getOrderInfo(flowAPI.essentials.httpClient, orderId)
            .then(async (order) => {
              const orderStatus = getOrderStatus(
                order.fulfillmentStatusesAggregate?.statuses?.at(0),
                order.status,
              );
              $w(selectors.trackerWidget).setStatus(orderStatus);
              await $w(selectors.trackerWidget).initDuplexer(order.id);

              updateOrderInfo({
                orderNumber: order.number,
                orderDetails: getOrderDetails(order),
                method: order.shippingInfo?.logistics?.pickupDetails
                  ? 'pickup'
                  : 'shipping',
              });
              $w(selectors.stateBox).changeState(states.ready);

              if (logger) {
                const { from, to } =
                  order.shippingInfo?.logistics?.deliveryTimeSlot ?? {};
                const fulfilledTimeFrom = from?.getTime();
                const fulfilledTimeTo = to?.getTime();
                const eventParams: TrackerPageLoadedParams = {
                  orderId: order.id ?? undefined,
                  orderStatus:
                    orderStatus === UNKNOWN ? undefined : orderStatus,
                  fulfilledTimeFrom,
                  fulfilledTimeTo:
                    fulfilledTimeTo && fulfilledTimeFrom !== fulfilledTimeTo
                      ? fulfilledTimeTo
                      : undefined,
                  fulfillmentType: order.shippingInfo?.logistics?.pickupDetails
                    ? TrackerFulfillmentType.PICKUP
                    : TrackerFulfillmentType.DELIVERY,
                };
                logger.report(oloLiveSiteTrackerPageLoaded(eventParams));
              }
            })
            .catch(() => {
              $w(selectors.stateBox).changeState(states.error);
            });
        }
        $bind(selectors.orderNumber, { text: () => orderInfo.orderNumber });
        $bind(selectors.methodDetailsRepeater, {
          data: () => orderInfo.orderDetails,
        });
        $bind(selectors.methodDetailsTitle, {
          text: () =>
            orderInfo.method === 'pickup'
              ? t('tracker.page.pickup-details.title')
              : t('tracker.page.delivery-details.title'),
        });
        $w(selectors.methodDetailsRepeater).onItemReady(
          ($item, itemData: OrderDetails) => {
            $item(selectors.orderDetailsItemTitle).text = t(itemData.titleKey);
            $item(selectors.orderDetailsItemValue).text = itemData.value;
          },
        );

        $widget.fireEvent('widgetLoaded', {});
      },
      exports: {},
    };
  },
);
