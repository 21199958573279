import { getOrder } from '@wix/ambassador-ecom-v1-order/build/cjs/http.impl';
import { Order } from '@wix/ambassador-ecom-v1-order/build/cjs/types.impl';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export async function getOrderInfo(
  httpClient: IHttpClient,
  orderid: string,
): Promise<Order> {
  const {
    data: { order },
  } = await httpClient.request(getOrder({ id: orderid }));

  if (!order) {
    throw new Error(
      `[Order Tracker] - get Order: Error when calling getOrder, Get empty order for orderId ${orderid}.`,
    );
  }

  return order;
}
