import { Order, Address } from '@wix/ambassador-ecom-v1-order/types';
import { OrderDetails } from '../utils/types';

const getAddress = (address: Address | undefined) => {
  const addressLine = [
    address?.addressLine,
    address?.city,
    [address?.subdivision, address?.postalCode]
      .filter((addressItem) => !!addressItem)
      .join(' '),
    address?.countryFullname,
  ];

  return addressLine.filter((addressItem) => !!addressItem).join(', ');
};

const getDueBy = (from?: Date, to?: Date): string => {
  const dateConfig: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: '2-digit',
    day: '2-digit',
  };
  const timeConfig: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  };
  const isRange = from && to && from.getTime() !== to.getTime();

  if (isRange) {
    return `${from.toLocaleDateString(
      undefined,
      dateConfig,
    )}, ${from.toLocaleTimeString(
      undefined,
      timeConfig,
    )} - ${to.toLocaleTimeString(undefined, timeConfig)}`;
  }

  return `${(from ?? to)!.toLocaleDateString(undefined, dateConfig)}, ${(from ??
    to)!.toLocaleTimeString(undefined, timeConfig)}`;
};

export function getOrderDetails(order: Order): OrderDetails[] {
  const orderDetails: OrderDetails[] = [];
  const deliveryDetails = order.shippingInfo?.logistics?.shippingDestination;
  const pickupDetails = order.shippingInfo?.logistics?.pickupDetails;

  if (deliveryDetails) {
    const address = getAddress(deliveryDetails.address);

    if (address) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.delivery-details.address.title',
        value: address,
      });
    }
  } else if (pickupDetails) {
    const address = getAddress(pickupDetails.address);

    if (address) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.pickup-details.address.title',
        value: address,
      });
    }
  }

  const { from, to } = order.shippingInfo?.logistics?.deliveryTimeSlot ?? {};
  if (from || to) {
    const dueBy = getDueBy(from, to);
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: pickupDetails
        ? 'tracker.page.pickup-details.due-by.title'
        : 'tracker.page.delivery-details.due-by.title',
      value: dueBy,
    });
  }

  const orderNote = order.buyerNote;
  if (orderNote) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.order-details.order-note.title',
      value: orderNote,
    });
  }

  return orderDetails;
}
